<script>
import {
  defineComponent, reactive, ref,
} from '@vue/composition-api'

import Tag from '@/components/Tag.vue'
import {
  MODIFY_TYPE_MAP, PROMISE_MAP, PROJECT_TYPE_OPTS,
} from '@core/utils/constant'
import Add from '@/views/projectPlans/components/Add.vue'
import tableCols from '@/views/projectPlans/tableCols'
import useSearch from '@/views/projectPlans/useSearch'
import { useMessage } from '@/hooks/useMessage'
import { editAuth } from '@core/utils/useAccess'
import dayjs from 'dayjs'
import { deleteProject } from './api'

export default defineComponent({
  components: {
    Add,
    Tag,
  },
  setup() {
    const showDrawer = ref(false)
    const showType = ref('')
    const currItem = ref({})

    const engineerPromiseOpts = ref([
      {
        key: PROMISE_MAP.DONE,
        tagKey: 'danger',
      },
      {
        key: PROMISE_MAP.TEST,
        tagKey: 'waring',
      },
      {
        key: PROMISE_MAP.START,
        tagKey: 'pending',
      },
      {
        key: PROMISE_MAP.NOPROMISE,
        tagKey: 'normal',
      },
    ])

    const { ...useSearchParams } = useSearch()
    const { MessageBox, message } = useMessage()

    const handleAdd = () => {
      showDrawer.value = true
      showType.value = MODIFY_TYPE_MAP.ADD
      currItem.value = {}
    }

    const handleEdit = item => {
      showDrawer.value = true
      showType.value = MODIFY_TYPE_MAP.EDIT
      currItem.value = item
    }

    const handleView = item => {
      showDrawer.value = true
      showType.value = MODIFY_TYPE_MAP.VIEW
      currItem.value = item
    }

    const handleRemove = item => {
      MessageBox({
        message: '您确定要删除该项目吗？',
        type: 'warning',
        showCancelButton: true,
      }).then(async () => {
        try {
          const { data: { status, message: messageText } } = await deleteProject(item.id)
          if (status === 200) {
            message.success('删除成功！')
            useSearchParams.handleRefresh()
          } else {
            message.error(messageText)
          }
        } catch (err) {
          message.error(err)
        }
      })
    }

    const filterData = (list = [], key, value, keyName) => {
      const valueArr = value.split(',') || []
      const result = []
      valueArr.forEach(row => {
        const filterItem = list.find(item => `${item[key]}` === `${row}`)
        if (filterItem) {
          result.push(filterItem[keyName])
        }
      })

      return result.join('\n') || '-'
    }

    const formateMonth = str => dayjs(str).format('YYYYMM')

    return {
      showType,
      showDrawer,
      editAuth,
      projectTypeOpts: reactive(PROJECT_TYPE_OPTS),
      engineerPromiseOpts,
      currItem,

      tableCols: reactive(tableCols),
      handleAdd,
      handleEdit,
      handleView,
      handleRemove,
      filterData,
      formateMonth,

      ...useSearchParams,
    }
  },
})
</script>
<template>
  <v-card elevation="0">
    <div style="padding: 12px 20px">
      <!--搜索区域-->
      <XwSearch
        :form-config="searchConfig"
        @search="search"
      />
    </div>
    <div style="width: 100%; padding: 6px 20px 12px">
      <!--按钮区域-->
      <v-btn
        color="primary"
        dark
        class="mr-3"
        @click="handleAdd"
      >
        新增项目
      </v-btn>
    </div>
    <!-- table -->
    <div style="padding: 0 20px">
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
      <XwTable
        ref="tableRef"
        :columns="tableCols"
        :rows="pageList"
        :loading="loading"
        show-overflow
        :row-config="{height: 70}"
        :tooltip-config="{showAll: true}"
      >
        <template #projectName="{row}">
          <div class="project-name">
            <Tag
              v-bind="{...projectTypeOpts.find(x=> x.value === row.projectType)}"
              style="padding: 0 2px"
            ></Tag>
            <div>
              <span>{{ row.versionNum }}</span>
              <div class="project-name-inner">
                {{ row.projectName }}
              </div>
            </div>
          </div>
        </template>
        <template #belongsTo="{row}">
          <div>{{ filterData(systemTypeOpts, 'code', row.belongsTo, 'name') }}</div>
        </template>
        <template #planAt="{row}">
          <div v-if="!row.planStartAt && !row.planEndAt">
            -
          </div>
          <div v-else-if="row.planStartAt === row.planEndAt || (row.planStartAt && !row.planEndAt)">
            {{ formateMonth(row.planStartAt) }}
          </div>
          <div v-else>
            {{ formateMonth(row.planStartAt) }}<span v-if="row.planStartAt && row.planEndAt">-</span>{{ formateMonth(row.planEndAt) }}
          </div>
        </template>
        <template #rdPromise="{row}">
          <div v-if="row.rdPromise">
            <Tag
              v-bind="{...engineerPromiseOpts.find(x=> x.key === row.rdPromiseState)}"
              :tag-name="row.rdPromiseMonth ? row.rdPromiseMonth + ' ' + row.rdPromise : row.rdPromise"
            ></Tag>
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #pmPriority="{row}">
          <div>{{ row.pmPriority }} <span v-if="row.cpoPriority">/ {{ row.cpoPriority }}</span></div>
        </template>
        <template #opts="{row}">
          <a
            v-if="editAuth(row.pmUserId)"
            @click="() => {handleEdit(row)}"
          >
            编辑
          </a>
          <a
            v-if="editAuth(row.pmUserId)"
            class="text--danger ml-2"
            @click="() => {handleRemove(row)}"
          >
            删除
          </a>
          <a
            v-if="!editAuth(row.pmUserId)"
            @click="() => {handleView(row)}"
          >
            详情
          </a>
        </template>
      </XwTable>

      <div
        v-if="pageLength"
      >
        <new-pagination
          :page-index="page"
          :page-size="pageSize"
          :total="pageLength"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!--新增项目弹框-->
    <Add
      v-if="showDrawer"
      v-model="showDrawer"
      :currid="currItem.versionNum"
      :show-type="showType"
      :manager-opts="managerOpts"
      :system-type-opts="systemTypeOpts"
      @refresh="handleRefresh"
    />
  </v-card>
</template>
<style lang="scss" scoped>
.project-name{
  display: flex;
  flex-direction: row;
  align-items: center;
  .project-name-inner{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
    font-weight: bold;
    color: #000;
  }
}
</style>
