const tableCols = [
  {
    text: '项目名称',
    sortable: false,
    width: 300,
    fixed: 'left',
    align: 'left',
    value: 'projectName',
    slots: 'projectName',
  },
  {
    text: '所属系统',
    sortable: false,
    width: 120,
    align: 'left',
    value: 'belongsTo',
    slots: 'belongsTo',
    showOverflow: false,
  },
  {
    text: '具体需求',
    sortable: false,
    minWidth: 350,
    align: 'left',
    value: 'demand',
  },
  {
    text: '优先级',
    sortable: false,
    width: 120,
    align: 'left',
    value: 'pmPriority',
    slots: 'pmPriority',
  },
  {
    text: '产品经理',
    sortable: false,
    width: 120,
    align: 'left',
    value: 'pmUserName',
  },
  {
    text: '推进计划',
    sortable: false,
    minWidth: 100,
    align: 'left',
    value: 'planAt',
    slots: 'planAt',
  },
  {
    text: '研发承诺',
    sortable: false,
    minWidth: 120,
    align: 'left',
    value: 'rdPromise',
    slots: 'rdPromise',
  },
  {
    text: '操作',
    sortable: false,
    width: 200,
    align: 'left',
    fixed: 'right',
    value: 'opts',
    slots: 'opts',
  },
]

export default tableCols
