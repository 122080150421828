import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"0"}},[_c('div',{staticStyle:{"padding":"12px 20px"}},[_c('XwSearch',{attrs:{"form-config":_vm.searchConfig},on:{"search":_vm.search}})],1),_c('div',{staticStyle:{"width":"100%","padding":"6px 20px 12px"}},[_c(VBtn,{staticClass:"mr-3",attrs:{"color":"primary","dark":""},on:{"click":_vm.handleAdd}},[_vm._v(" 新增项目 ")])],1),_c('div',{staticStyle:{"padding":"0 20px"}},[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c('XwTable',{ref:"tableRef",attrs:{"columns":_vm.tableCols,"rows":_vm.pageList,"loading":_vm.loading,"show-overflow":"","row-config":{height: 70},"tooltip-config":{showAll: true}},scopedSlots:_vm._u([{key:"projectName",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"project-name"},[_c('Tag',_vm._b({staticStyle:{"padding":"0 2px"}},'Tag',Object.assign({}, _vm.projectTypeOpts.find(function (x){ return x.value === row.projectType; })),false)),_c('div',[_c('span',[_vm._v(_vm._s(row.versionNum))]),_c('div',{staticClass:"project-name-inner"},[_vm._v(" "+_vm._s(row.projectName)+" ")])])],1)]}},{key:"belongsTo",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.filterData(_vm.systemTypeOpts, 'code', row.belongsTo, 'name')))])]}},{key:"planAt",fn:function(ref){
var row = ref.row;
return [(!row.planStartAt && !row.planEndAt)?_c('div',[_vm._v(" - ")]):(row.planStartAt === row.planEndAt || (row.planStartAt && !row.planEndAt))?_c('div',[_vm._v(" "+_vm._s(_vm.formateMonth(row.planStartAt))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.formateMonth(row.planStartAt))),(row.planStartAt && row.planEndAt)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v(_vm._s(_vm.formateMonth(row.planEndAt))+" ")])]}},{key:"rdPromise",fn:function(ref){
var row = ref.row;
return [(row.rdPromise)?_c('div',[_c('Tag',_vm._b({attrs:{"tag-name":row.rdPromiseMonth ? row.rdPromiseMonth + ' ' + row.rdPromise : row.rdPromise}},'Tag',Object.assign({}, _vm.engineerPromiseOpts.find(function (x){ return x.key === row.rdPromiseState; })),false))],1):_c('div',[_vm._v(" - ")])]}},{key:"pmPriority",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.pmPriority)+" "),(row.cpoPriority)?_c('span',[_vm._v("/ "+_vm._s(row.cpoPriority))]):_vm._e()])]}},{key:"opts",fn:function(ref){
var row = ref.row;
return [(_vm.editAuth(row.pmUserId))?_c('a',{on:{"click":function () {_vm.handleEdit(row)}}},[_vm._v(" 编辑 ")]):_vm._e(),(_vm.editAuth(row.pmUserId))?_c('a',{staticClass:"text--danger ml-2",on:{"click":function () {_vm.handleRemove(row)}}},[_vm._v(" 删除 ")]):_vm._e(),(!_vm.editAuth(row.pmUserId))?_c('a',{on:{"click":function () {_vm.handleView(row)}}},[_vm._v(" 详情 ")]):_vm._e()]}}])}),(_vm.pageLength)?_c('div',[_c('new-pagination',{attrs:{"page-index":_vm.page,"page-size":_vm.pageSize,"total":_vm.pageLength},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1):_vm._e()],1),(_vm.showDrawer)?_c('Add',{attrs:{"currid":_vm.currItem.versionNum,"show-type":_vm.showType,"manager-opts":_vm.managerOpts,"system-type-opts":_vm.systemTypeOpts},on:{"refresh":_vm.handleRefresh},model:{value:(_vm.showDrawer),callback:function ($$v) {_vm.showDrawer=$$v},expression:"showDrawer"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }