import axios from '@axios'
import url from './url'
import config from '../../../config'

const prefix = config.serviceUrl

/**
 * 获取项目列表
 * @param {page} 页码
 * @param {pageSize} 每页条数
 * @param {belongsTo} 优先级
 * @param {pmPriority} 所属系统
 * @param {pmUserName} 产品经理姓名
 * @param {planStartAt} 推进计划开始年月
 * @param {planEndAt} 推进计划结束年月
 */

export const getPage = params => {
  const { page, pageSize, ...others } = params

  return axios.post(prefix + url.PAGE, { ...others }, { params: { page, pageSize } })
}

/**
 * 获取产品经理列表
 */
export const getManager = () => axios.get(prefix + url.MANAGER)

/**
 * 获取研发人员列表
 */
export const getEngineer = () => axios.get(prefix + url.ENGINEER)

/**
 * 获取项目详情
 * @param {versionNum} 版本号
 */
export const getProjectInfo = params => axios.get(prefix + url.INFO, { params })

/**
 * 获取所属系统
 */
export const getSystemType = () => axios.get(prefix + url.SYSTEM)

/**
 * 项目删除
 * @param {id} 项目id
 */
export const deleteProject = id => axios.delete(`${prefix + url.DELETE}/${id}`)

/**
 * 项目保存
 * @param {background} 背景及价值
 * @param {belongsTo} 所属系统
 * @param {belongsToArray} 所属系统（多选 以，隔开）传code值 10：c端产品 20：招募系统
 * @param {cpoPriority} 优先级（产品总监调整后）
 * @param {pmPriority} 优先级
 * @param {demand} 具体需求
 * @param {expectOnlineAt} 期望上线时间
 * @param {planEndAt} 推进计划结束年月
 * @param {planStartAt} 推进计划开始年月
 * @param {pmUserId} 产品经理ID
 * @param {pmUserName} 产品经理姓名
 * @param {prdUrl} PRD地址
 * @param {projectName} 项目名称
 * @param {projectType} 项目类型 0:业务型 1:技术型
 * @param {rdPromise} 研发承诺
 * @param {requirementReviewAt} 需求评审时间
 * @param {versionNum} 项目编号
 */

export const saveProject = params => axios.post(prefix + url.SAVE, params)
