import { getCurrentInstance } from '@vue/composition-api'

export function useMessage() {
  const vm = getCurrentInstance().proxy

  const MessageBox = vm.$msgbox
  const message = vm.$message
  const confirm = vm.$confirm
  const alert = vm.$alert

  return {
    MessageBox,
    message,
    confirm,
    alert,
  }
}
