import {
  computed,
} from '@vue/composition-api'
import { required, urlValidator } from '@core/utils/validation'
import { PROJECT_TYPE_OPTS, PROMISE_MAP } from '@core/utils/constant'
import dayjs from 'dayjs'

export default function useForm({ systemTypeOpts, managerOpts, formData = {} }) {
  const userData = JSON.parse(localStorage.getItem('userData')) || {}
  const user = userData.user || {}
  const formConfig = computed(() => [
    {
      element: 'VTextField',
      props: 'projectName',
      initValue: formData.projectName,
      label: '项目名称',
      placeholder: '请填写',
      maxLength: 200,
      rules: [
        required,
      ],
      required: true,
    },
    {
      element: 'VAutocomplete',
      props: 'belongsTo',
      placeholder: '请选择',
      label: '所属系统',
      items: systemTypeOpts,
      multiple: true,
      itemValue: 'code',
      itemText: 'name',
      initValue: formData.belongsTo?.split(',')?.map(item => Number(item)),
      viewText: () => {
        const valueArr = formData.belongsTo.split(',') || []
        const result = []
        valueArr.forEach(row => {
          const filterItem = systemTypeOpts.find(item => `${item.code}` === `${row}`)
          if (filterItem) {
            result.push(filterItem.name)
          }
        })

        return result.join('\n')
      },
      rules: [
        required,
      ],
      required: true,
    },
    {
      element: 'VTextField',
      props: 'versionNum',
      label: '版本号',
      initValue: formData.versionNum,
      disabled: !!formData.versionNum,
      placeholder: '请填写',
      rules: [
        required,
      ],
      required: true,
    },
    {
      element: 'RideoTag',
      props: 'projectType',
      initValue: formData.projectType || PROJECT_TYPE_OPTS[0].value,
      label: '项目类型',
      items: PROJECT_TYPE_OPTS,
      itemText: 'label',
      itemValue: 'value',
      viewText: () => PROJECT_TYPE_OPTS.find(x => x.value === formData.projectType)?.label,
      rules: [
        required,
      ],
      required: true,
    },
    {
      element: 'VTextarea',
      props: 'background',
      initValue: formData.background,
      label: '背景及价值',
      placeholder: '请填写',
      maxLength: 800,
      rules: [
        required,
      ],
      required: true,
    },
    {
      element: 'VTextarea',
      props: 'demand',
      initValue: formData.demand,
      label: '具体需求',
      placeholder: '请填写',
      maxLength: 800,
      rules: [
        required,
      ],
      required: true,
    },
    {
      element: 'VTextField',
      props: 'prdUrl',
      initValue: formData.prdUrl,
      label: 'PRD',
      placeholder: '请填写PRD地址',
      maxLength: 200,
      rules: [urlValidator],
      isLink: true,
    },
    {
      element: 'RideoTag',
      props: 'pmPriority',
      initValue: formData.pmPriority,
      label: '优先级',
      items: ['P0', 'P1', 'P2', 'P3'],
      rules: [
        required,
      ],
      required: true,
    },
    {
      element: 'VAutocomplete',
      props: 'pmUserId',
      initValue: formData.pmUserId || user.userId,
      placeholder: '请选择',
      label: '产品经理',
      items: managerOpts,
      itemText: 'name',
      itemValue: 'userId',
      multiple: false,
      viewText: () => managerOpts.find(x => x.userId === formData.pmUserId)?.name,
    },
    {
      element: 'DateRangePickers',
      props: 'planAt',
      label: '推进计划',
      placeholder: '推进计划',
      range: true,
      type: 'month',
      initValue: [formData.planStartAt, formData.planEndAt],
      searchKey: ['planStartAt', 'planEndAt'],
      disabled: formData.rdPromiseState && PROMISE_MAP.NOPROMISE !== formData.rdPromiseState,
      allowedDates: day => dayjs(day).isAfter(dayjs().subtract(1, 'month')),
      viewText: () => {
        if (formData?.planStartAt && formData?.planEndAt && formData?.planEndAt !== formData?.planStartAt) {
          return `${dayjs(formData?.planStartAt).format('YYYYMM')}-${dayjs(formData?.planEndAt).format('YYYYMM')}`
        }

        if (formData?.planStartAt && (formData?.planEndAt === formData?.planStartAt || !formData.planEndAt)) {
          return `${dayjs(formData?.planStartAt).format('YYYYMM')}`
        }

        return '-'
      },
      rules: [
        required,
      ],
      required: true,
    },
    {
      element: 'DateRangePickers',
      props: 'requirementReviewAt',
      initValue: formData.requirementReviewAt,
      label: '需求评审时间',
      placeholder: '需求评审时间',
      rules: [
        required,
      ],
      required: true,
    },
    {
      element: 'DateRangePickers',
      props: 'expectOnlineAt',
      initValue: formData.expectOnlineAt,
      label: '期望上线时间',
      placeholder: '期望上线时间',
      appendSlots: 'expectOnlineAt',
    },
  ])

  return {
    formConfig,
  }
}
