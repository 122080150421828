export const MODIFY_TYPE_MAP = {
  ADD: 'add', // 新增
  EDIT: 'edit', // 编辑
  VIEW: 'view', // 预览
}

export const TAG_CONFIG_ITEMS = [
  {
    bgColor: 'rgba(44,160,248,0.06)',
    bdColor: 'rgba(44, 160, 248, 0.20)',
    color: 'rgba(44, 160, 248, 1)',
    key: 'pending',
  },
  {
    bgColor: 'rgba(242,69,69,0.06)',
    bdColor: 'rgba(242, 69, 69, 0.20)',
    color: 'rgba(242, 69, 69, 1)',
    key: 'danger',
  },
  {
    bgColor: 'rgba(255,168,15,0.06)',
    bdColor: 'rgba(255, 168, 15, 0.20)',
    color: 'rgba(255, 168, 15, 1)',
    key: 'waring',
  },
  {
    bgColor: 'rgba(255,255,255,1)',
    bdColor: 'rgba(145, 85, 253, 1)',
    color: 'rgba(145, 85, 253, 1)',
    key: 'normal',
  },
]

export const TAG_CONFIG_MAP = {
  PENDING: 'pending',
  DANGER: 'danger',
  WARING: 'waring',
  PRIMARY: 'normal',
}

export const PROJECT_TYPE_MAP = {
  TEACH: 1, // 技术
  BUSINESS: 0, // 业务
}

export const PROJECT_TYPE_OPTS = [
  {
    value: PROJECT_TYPE_MAP.BUSINESS,
    label: '业务类型项目',
    tagKey: TAG_CONFIG_MAP.WARING,
    tagName: '业',
  }, {
    value: PROJECT_TYPE_MAP.TEACH,
    label: '技术类型项目',
    tagKey: TAG_CONFIG_MAP.PENDING,
    tagName: '技',
  },
]

export const PRIORITY_LIST = ['P0', 'P1', 'P2', 'P3']

export const PROMISE_MAP = {
  DONE: 1, // 交付试用
  TEST: 2, // 测试
  START: 3, // 启动
  NOPROMISE: 4, // 不承诺
}

export const MONTH_TARGET_MAP = [
  {
    value: PROMISE_MAP.DONE,
    label: '交付试用',
    tagKey: TAG_CONFIG_MAP.DANGER,
  },
  {
    value: PROMISE_MAP.TEST,
    label: '提测',
    tagKey: TAG_CONFIG_MAP.WARING,
  },
  {
    value: PROMISE_MAP.START,
    label: '仅启动',
    tagKey: TAG_CONFIG_MAP.PENDING,
  },
]

export const PROGRESS_MAP = {
  DONE: '4', // 交付试用
  TEST: '3', // 测试中
  DEV: '2', // 开发中
  START: '1', // 已评审待开发
  UNSTART: '0', // 未开始
}

export const PROGRESS_MAP_OPTS = [
  {
    value: PROGRESS_MAP.UNSTART,
    label: '未开始',
    tagKey: TAG_CONFIG_MAP.PENDING,
  },
  {
    value: PROGRESS_MAP.START,
    label: '已评审待开发',
    tagKey: TAG_CONFIG_MAP.PRIMARY,
  },
  {
    value: PROGRESS_MAP.DEV,
    label: '开发中',
    tagKey: TAG_CONFIG_MAP.WARING,
  },
  {
    value: PROGRESS_MAP.TEST,
    label: '测试中',
    tagKey: TAG_CONFIG_MAP.WARING,
  },
  {
    value: PROGRESS_MAP.DONE,
    label: '已上线',
    tagKey: TAG_CONFIG_MAP.DANGER,
  },
]
