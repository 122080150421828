import {
  computed, reactive, ref, onMounted,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { PRIORITY_LIST } from '@core/utils/constant'
import { getPage, getManager, getSystemType } from './api'

export default function useSearch() {
  const loading = ref(false)
  const page = ref(1)
  const pageSize = ref(10)
  const pageLength = ref(0)
  const managerOpts = ref([])
  const pageList = ref([])
  const systemTypeOpts = ref([])

  const { message } = useMessage()

  // 查询初始化参数
  const queryDataOri = {
    belongsTo: null,
    planStartAt: null,
    pmPriority: null,
    pmUserName: null,
    planEndAt: null,
  }

  // 查询参数增加响应
  const queryData = reactive({ ...queryDataOri })

  // 方便外部传出初始值合并
  const initData = { ...queryDataOri }

  const fetchManager = async () => {
    try {
      const { data: { data = [] } } = await getManager()
      managerOpts.value = data
    } catch (err) {
      message.error(err)
      console.log(err)
    }
  }

  const fetchSystemType = async () => {
    try {
      const { data: { data = [] } } = await getSystemType()
      systemTypeOpts.value = data
    } catch (err) {
      message.error(err)
      console.log(err)
    }
  }

  const searchConfig = computed(() => [
    {
      element: 'VAutocomplete',
      props: 'belongsTo',
      placeholder: '所属系统',
      label: '所属系统',
      items: systemTypeOpts.value,
      multiple: false,
      itemValue: 'code',
      itemText: 'name',
    },
    {
      element: 'VAutocomplete',
      props: 'pmUserName',
      placeholder: '产品经理',
      label: '产品经理',
      items: managerOpts.value,
      multiple: false,
      itemValue: 'name',
      itemText: 'name',
    },
    {
      element: 'VAutocomplete',
      props: 'pmPriority',
      placeholder: '优先级',
      label: '优先级',
      items: PRIORITY_LIST,
      multiple: false,
    },
    {
      element: 'DateRangePickers',
      props: 'time',
      searchKey: ['planStartAt', 'planEndAt'],
      initValue: [initData.planStartAt, initData.planEndAt],
      placeholder: '起止月份',
      label: '起止月份',
      type: 'month',
      clearable: false,
      range: true,
    },
  ])

  const fetchList = async () => {
    loading.value = true
    try {
      const { data: { data: { total, records = [] }, status, message: messageText } } = await getPage({
        ...queryData,
        page: page.value,
        pageSize: pageSize.value,
      })
      loading.value = false
      if (status === 200) {
        pageList.value = [...records]
        pageLength.value = total
      } else {
        message.error(messageText)
      }
    } catch (err) {
      message.error(err)
      loading.value = false
    }
  }

  const search = async params => {
    page.value = 1
    Object.assign(queryData, params)

    fetchList()
  }

  const handleSizeChange = val => {
    page.value = 1
    pageSize.value = val
    fetchList()
  }
  const handleCurrentChange = val => {
    page.value = val
    fetchList()
  }

  const handleRefresh = () => {
    page.value = 1
    pageSize.value = 10
    fetchList()
  }

  onMounted(() => {
    fetchManager()
    fetchSystemType()
    fetchList()
  })

  return {
    page,
    pageSize,
    pageLength,
    searchConfig,
    queryData,
    loading,
    pageList,
    managerOpts,
    systemTypeOpts,
    search,
    fetchList,
    handleSizeChange,
    handleCurrentChange,
    handleRefresh,
  }
}
