<script>
import { defineComponent, computed } from '@vue/composition-api'
import { TAG_CONFIG_ITEMS } from '@core/utils/constant'

export default defineComponent({
  name: 'Tag',
  props: {
    tagName: {
      type: String,
      default: '',
    },
    tagKey: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const itemConfig = computed(() => TAG_CONFIG_ITEMS.find(x => x.key === props.tagKey) || {})

    return {
      itemConfig,
    }
  },
})
</script>

<template>
  <span
    class="tag"
    :style="`background-color: ${itemConfig.bgColor}; border-color: ${itemConfig.bdColor}; color: ${itemConfig.color}`"
  >{{ tagName }}</span>
</template>
<style lang="scss" scoped>
.tag{
  width: auto;
  border: 1px solid #ddd;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  text-align: center;
  margin-right: 15px;
  line-height: 16px;
}
</style>
